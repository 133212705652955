<template>
  <div class="book">
    <div class="book-header">
      <span class="big-title" @click="courseRoot()">我的课程</span>
      <span class="breadcrumb" v-for="item in brouters" :key="item.path">
        <img src="@/assets/breadcrumb.png" alt="" srcset="">
        <span @click="backAction(item.path)">{{item.title}}</span>
      </span>
    </div>
    <div class="clock-warp">
      <clock></clock>
    </div>
   <router-view></router-view>
  </div>
</template>

<script>
import Clock from "../../components/Clock.vue";
export default {
  components: {
    Clock,
  },
  computed: {
    brouters () {
      return this.$store.state.bookRouters
    }
  },
  methods: {
    courseRoot(){
      if(this.$route.path == '/book/course') {
        return
      }
      this.$store.commit('M_ADD_BOOK_ROUTER',[])
      this.$router.push('/book/course')
    },
    backAction(path) {
      console.log('route', this.$route)
      if(this.$route.fullPath == path) {
        return
      }
      let routers = this.brouters;
      while(routers.pop() == path) {
          break
      }
      this.$store.commit('M_ADD_BOOK_ROUTER',routers)
      this.$router.push(path)
    }
  }
};

Clock;
</script>

<style lang="less">
.book {
  height: 90%;
  position: relative;
  .book-header {
    display: flex;
    font-size: 18Px;
     line-height: 36px; 
      color: #2a2a31;
      font-weight: bold;
      span {
        color: #2a2a31;
        padding-left: 6Px;
        display: inline-block;
        cursor: pointer;
      }
  }
  .big-title {
    font-size: 18Px;
    font-family: SourceHanSansCN-Medium;
  }
  .big-title::after {
    content: "";
    width: 60px;
    height: 4px;
    background: #6e76ff;
    opacity: 1;
    border-radius: 5px;
    display: block;
    margin-top: 7px;
  }
  .clock-warp {
    position: absolute;
    right: 30px;
    top: 0;
  }
  .breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 8Px;
    margin-bottom: 4Px;
    padding-top: 2Px;
    img {
      position: relative;
      top: -2Px;
    }
  }
  .router-link-active,.router-link-exact-active {
      background:none;
    }
}
</style>